import dayjs from 'dayjs'
import { columnChartOptions } from '@/config/dashboard'
import {
  getChartData,
  truncateDecimal,
  getDateOfRange,
} from '@/utils/dashboard'
import { getCssVar } from '@/utils/helper'

const yaxisTitleConfig = {
  sumAmount: '金額',
  count: '單數',
  avgAmount: '金額',
  avgMemberCount: '單數',
}

//  會員人數趨勢default設定
export const generateGanderConfig = (labels = [], series = [], yaxisTitle) => ({
  ...columnChartOptions,
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
    getCssVar('chart-5'),
  ],
  xaxis: {
    type: 'date',
    title: {
      text: '性別',
    },
  },
  yaxis: [
    {
      title: {
        text: yaxisTitle,
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: true,
    position: 'top',
  },
  plotOptions: {
    bar: {
      columnWidth: '20',
      dataLabels: {
        position: 'top',
      },
    },
  },
  stroke: {
    show: true,
    width: 5,
    colors: ['transparent'],
  },
  labels,
  series,
})

const labels = {
  male: '男',
  female: '女',
  other: '其他',
}

//  初始化資料
const initDataSet = (typeKeys, genderKeys) => {
  const obj = {}
  typeKeys.forEach((typeKey) => {
    obj[typeKey] = {
      name: typeKey,
      data: genderKeys.map(() => 0),
    }
  })
  return obj
}

export const getPurchaseData = async ({
  shopId,
  interval,
  target,
  typeKeys = [],
}) => {
  const dateRange = getDateOfRange({
    interval,
    splitType: 'day',
  })

  const { result, lastUpdatedAt } = await getChartData({
    shopId,
    key: 'transactionRateByGenderAndType',
    dateTrunc: 'day',
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
  })

  const genderKeys = Object.keys(labels)

  const dataset = initDataSet(typeKeys, genderKeys)

  //  other是因為api其他性別的部分回傳為null，為避免碰到關鍵字故改other
  result.forEach((item) => {
    const genderIndex = genderKeys.findIndex(
      (key) => key === (item.gender || 'other'),
    )
    if (dataset[item.type]) {
      dataset[item.type].data[genderIndex] = truncateDecimal(item[target])
    }
  })

  const yaxisTitle = yaxisTitleConfig[target]

  return {
    newOptions: generateGanderConfig(
      Object.values(labels),
      Object.values(dataset),
      yaxisTitle,
    ),
    lastUpdatedAt,
  }
}
